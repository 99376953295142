import React, { type FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { cn } from 'class-merge';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import type { WalletInfoInterface } from '../../types/wallet';
import type { UserInfoInterface } from '../../types/user';
import { useRouter } from '~hooks/use-router';
import { useLotto } from '~hooks/use-lotto';
import { useSportsbook } from '~hooks/use-sports-book';
import { DEFAULT_CURRENCY } from '~constants/etc';
import appConfig from '~build-config/config.json';
import { ProviderStatusEnum } from '~constants/status';
import { ProviderTypeEnum } from '~constants/promotion';
import getPathLottoUrl from '~libs/get-path-lotto-url';
import HttpErrorHandler from '~components/http-error-handler';
import { onOpenSportURL } from '~libs/open-url';
import PageUrls from '~constants/page-urls';
import BentoMenuWidget from '~components/widget/bento-menu-widget';
import { useUserInfo } from '~hooks/use-user';
import { useWalletInfo } from '~hooks/use-wallet';
import MenuIcon, { menuIcons } from '~components/widget/menu-icon';

const PopularProviderContainer: any = dynamic(
  () => import('~containers/game-feature/popular-provider-container'),
  {
    ssr: true,
  },
);

interface HomepageMenuInterface {
  type: string;
  name: string;
  width?: number;
  height?: number;
  className?: string;
  text: string;
  href: string;
  props?: Record<string, any>;
}

const defaultHomepageMenu = [
  {
    type: 'icon',
    name: 'casino',
    text: 'casino-menu',
    href: PageUrls.CASINO,
  },
  {
    type: 'icon',
    name: 'gaming',
    text: 'gaming-menu',
    href: PageUrls.GAMING,
  },
  {
    type: 'icon',
    name: 'sport',
    text: 'sport-menu',
    href: PageUrls.SPORT,
  },
  {
    type: 'icon',
    name: 'reward',
    text: 'mission-menu',
    href: PageUrls.REWARD,
  },
  {
    type: 'icon',
    name: 'announcement',
    text: 'announcement-menu',
    href: PageUrls.ANNOUNCE,
  },
  {
    type: 'icon',
    name: 'contact',
    text: 'contact-menu',
    href: PageUrls.CONTACT,
  },
];

const HomepageCategoryLists: FC<{
  walletInfo: WalletInfoInterface | undefined;
  userInfo: UserInfoInterface | undefined;
}> = ({ walletInfo, userInfo }) => {
  const router = useRouter();
  const { t } = useTranslation('menus');
  const currency = walletInfo?.currency || DEFAULT_CURRENCY;
  const { data: lotto } = useLotto({
    currency,
  });
  const { data: sportsbook } = useSportsbook({
    currency,
  });
  const categoryMenus = appConfig.customCategoryMenu || [];
  const categorySubMenus = appConfig.customCategorySubMenu || [];
  const isLottoComingSoon =
    lotto?.[0]?.status === ProviderStatusEnum.COMINGSOON &&
    userInfo?.roles.every((res) => res === 'user');

  const getMenuListsMapped = (
    menus,
  ): {
    name: string;
    text: string;
    href: string;
    props: {
      bgColor: string;
      mascotImage: string;
      bgImage: string;
      className?: string;
    };
    icon: string;
  }[] => {
    return (
      menus.map((res) => ({
        ...res,
        icon: menuIcons[res.name].icon(),
      })) || []
    );
  };

  const categoryMenuLists = useMemo(() => {
    const menus = isLottoComingSoon
      ? categoryMenus.filter((res) => res.name !== ProviderTypeEnum.LOTTO)
      : categoryMenus;
    return getMenuListsMapped(menus);
  }, [categoryMenus, isLottoComingSoon]);

  const categorySubMenuLists = useMemo(() => {
    const menus =
      isLottoComingSoon || !lotto?.count
        ? categorySubMenus.filter((res) => res.name !== ProviderTypeEnum.LOTTO)
        : categorySubMenus;
    return getMenuListsMapped(menus);
  }, [categorySubMenus, isLottoComingSoon]);

  const handleOpenLotto = async (): Promise<void> => {
    try {
      window.open(
        await getPathLottoUrl(lotto?.[0].id, currency, walletInfo?.objectId),
        '_blank',
      );
    } catch (e) {
      HttpErrorHandler(e);
    }
  };

  const handleOpenSportsbook = async (): Promise<void> => {
    const currencyFiltered = sportsbook?.[0]?.currencies
      .filter((x) => x.includes(currency))
      .at(0);
    try {
      const response = await onOpenSportURL(
        String(sportsbook?.[0]?.objectId),
        currencyFiltered,
        walletInfo?.objectId,
        String(sportsbook?.[0]?.id),
      );
      window.open(response, '_blank');
    } catch (e) {
      HttpErrorHandler(e);
    }
  };

  // const handleOpenGame = async (
  //   id: string,
  //   gameCode: string,
  // ): Promise<void> => {
  //   try {
  //     const providers = await request<Pagination<ProviderGamingListResApi[]>>({
  //       url: `/gaming`,
  //       method: 'GET',
  //       params: {
  //         page: 1,
  //         perPage: 100,
  //         currency,
  //       },
  //     });
  //     const providerValue = providers?.records?.find((res) => res.id === id);
  //     const currencyFiltered = providerValue?.currencies
  //       .filter((x) => x.includes(currency))
  //       .at(0);
  //     const queryString = objectToQueryString({
  //       category: ProviderTypeEnum.GAMING,
  //       providerId: providerValue?.objectId,
  //       providerName: providerValue?.id,
  //       gameCode,
  //       currency: currencyFiltered,
  //       walletId: walletInfo?.objectId,
  //     });
  //     window.open(
  //       `/${locale}${PageUrls.OPEN_GAME}?qs=${encrypt(queryString)}`,
  //       '_self',
  //     );
  //   } catch (e) {
  //     HttpErrorHandler(e);
  //   }
  // };

  const handleOpenProvider = (item: {
    href: string;
    name: string;
    text: string;
  }) => {
    if (item.name === ProviderTypeEnum.GAMEPACA) {
      return router.push(`${PageUrls.GAMING}/${item.name}`);
    }
    if (item.name === ProviderTypeEnum.LOTTO) {
      return handleOpenLotto();
    }
    if (item.name === ProviderTypeEnum.SPORT && sportsbook?.count === 1) {
      return handleOpenSportsbook();
    }
    router.push(item.href);
  };

  if (!categorySubMenuLists.length && !categoryMenuLists.length) {
    return <div />;
  }
  return (
    <div className="flex flex-col">
      <div
        className={cn(
          'mt-4 grid gap-2',
          `grid-cols-${categoryMenuLists.length}`,
        )}
      >
        {categoryMenuLists.map((category, i) => (
          <div
            aria-hidden
            className="relative min-h-[90px] w-full cursor-pointer rounded-lg bg-cover bg-no-repeat px-4 py-3 hover:opacity-90"
            key={i}
            onClick={() => handleOpenProvider(category)}
            role="button"
            style={{
              backgroundColor: category.props.bgColor,
              backgroundImage: `url(${category.props.bgImage})`,
            }}
          >
            <div className="relative z-10 flex h-full flex-col justify-between">
              <div className="text-2xl">{category.icon}</div>
              <p>{t(`${category.text}` as any)}</p>
            </div>
            {category.props.mascotImage ? (
              <Image
                alt={category.text}
                className={cn(
                  'absolute bottom-0 right-0 h-full object-contain',
                  category.props.className,
                )}
                draggable={false}
                height={120}
                src={category.props.mascotImage}
                unoptimized
                width={120}
              />
            ) : null}
          </div>
        ))}
      </div>
      <div
        className={cn(
          'mt-2 grid grid-cols-1 gap-2',
          `sm:grid-cols-${categorySubMenuLists.length}`,
        )}
      >
        {categorySubMenuLists.map((category, i) => (
          <div
            aria-hidden
            className="relative min-h-[90px] w-full cursor-pointer rounded-lg px-4 py-3 hover:opacity-90"
            key={i}
            onClick={() => handleOpenProvider(category)}
            role="button"
            style={{
              backgroundColor: category.props.bgColor,
            }}
          >
            <div className="relative z-10 flex h-full flex-col justify-between">
              <div className="text-2xl">{category.icon}</div>
              <p>{t(`${category.text}` as any)}</p>
            </div>
            {category.props.mascotImage ? (
              <Image
                alt={category.text}
                className={cn(
                  'absolute bottom-0 right-0 h-full',
                  category.props.className,
                )}
                draggable={false}
                height={120}
                src={category.props.mascotImage}
                unoptimized
                width={120}
              />
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
};

const HomepageMenuLists: FC<{
  walletInfo: WalletInfoInterface | undefined;
  userInfo: UserInfoInterface | undefined;
}> = ({ walletInfo, userInfo }) => {
  const router = useRouter();
  const currency = walletInfo?.currency || DEFAULT_CURRENCY;
  // const { data: lotto } = useLotto(currency);
  const { data: sportsbook } = useSportsbook({
    currency,
  });
  const { t } = useTranslation('menus');

  const menuLists: HomepageMenuInterface[] =
    appConfig.customHomepageMenu || defaultHomepageMenu;
  // const isLottoComingSoon =
  //   lotto?.[0]?.status === ProviderStatusEnum.COMINGSOON &&
  //   userInfo?.roles.every((res) => res === 'user');

  // const handleOpenLotto = async (): Promise<void> => {
  //   try {
  //     window.open(
  //       await getPathLottoUrl(lotto?.[0].id, currency, walletInfo?.objectId),
  //       '_blank',
  //     );
  //   } catch (e) {
  //     HttpErrorHandler(e);
  //   }
  // };

  const handleOpenSportsbook = async (): Promise<void> => {
    const currencyFiltered = sportsbook?.[0]?.currencies
      .filter((x) => x.includes(currency))
      .at(0);
    try {
      const response = await onOpenSportURL(
        String(sportsbook?.[0]?.objectId),
        currencyFiltered,
        walletInfo?.objectId,
        String(sportsbook?.[0]?.id),
      );
      window.open(response, '_blank');
    } catch (e) {
      HttpErrorHandler(e);
    }
  };

  const handleOpenProvider = (item: HomepageMenuInterface) => {
    // if (item.name === ProviderTypeEnum.LOTTO) {
    //   return handleOpenLotto();
    // }
    if (item.name === ProviderTypeEnum.SPORT && sportsbook?.count === 1) {
      return handleOpenSportsbook();
    }
    router.push(item.href);
  };

  const menuListsMapped = useMemo(() => {
    if (!userInfo) {
      return menuLists.filter((res) => res.name !== 'reward');
    }
    return menuLists;
  }, [menuLists, userInfo]);

  return menuListsMapped.map((menu, i) => {
    return (
      <BentoMenuWidget
        icon={<MenuIcon menu={menu} />}
        key={i}
        onClick={() => handleOpenProvider(menu)}
        text={t(`${menu.text}` as any)}
      />
    );
  });
};

const HomepageGridLayout: FC = () => {
  const { data: userInfo } = useUserInfo();
  const { data: walletInfo } = useWalletInfo(
    userInfo?.selectedWallet.objectId || '',
  );
  return (
    <>
      <HomepageCategoryLists userInfo={userInfo} walletInfo={walletInfo} />
      <div className="xs:grid-cols-4 my-4 grid h-full grid-cols-2 gap-2">
        <HomepageMenuLists userInfo={userInfo} walletInfo={walletInfo} />
      </div>
      <PopularProviderContainer />
    </>
  );
};

export default HomepageGridLayout;
